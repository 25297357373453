@import '/theme.css';

.componentBase {
  perspective: 1000px;

  & > .heading {
    margin-bottom: sizeLg;
  }

  & > .heading {
    margin-bottom: sizeLg;
  }
}

.heading,
.content {
  &.visible {
    opacity: 1;
    transition: opacity easeInOutProductive durationS durationS;
    pointer-events: auto;
  }

  &:not(.visible) {
    opacity: 0;
    transition: opacity easeInOutProductive durationS;
    pointer-events: none;
  }
}

.heading {
  transform-origin: 0 100%;

  &.forwards.visible {
    animation: fold-top-down durationS durationS both;
  }

  &.backwards:not(.visible) {
    animation: fold-top-up durationS both;
  }
}

.content {
  transform-origin: 0 0;

  &.forwards:not(.visible) {
    animation: fold-bottom-up durationS both;
  }

  &.backwards.visible {
    animation: fold-bottom-down durationS durationS both;
  }

  & > :not(:last-child) {
    margin-bottom: sizeX2s;
  }
}

@keyframes fold-top-up {
  100% {
    transform: rotate3d(-8, 0, 0, 35deg);
  }
}

@keyframes fold-top-down {
  0% {
    transform: rotate3d(-8, 0, 0, 50deg);
  }
}


@keyframes fold-bottom-up {
  100% {
    transform: rotate3d(8, 0, 0, 35deg);
  }
}

@keyframes fold-bottom-down {
  0% {
    transform: rotate3d(8, 0, 0, 50deg);
  }
}
